import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
// import Overlay from "./Overlay";
import Info from "./Info";
// import How from "./How";
import Home from './Home';
import Services from './Services';
import About from './About';
import SatBanner from './SatBanner';
import Gallery from './Gallery';
import Testimonials from './Testimonials';
import Sessions from './Sessions';
// import Pricing from '../Pricing/Pricing';
import "./style.css";
import Hub from './Hub';
import Partners from './Partners';
import SessionsCoding from './SessionsCoding';
import Branches from './Branches';
import DronePilotingSessions from './DronePiloting';
import VirtualRealitySessions from './VIrtualReality';

//visible header that contains LOGO and Navigation Icon

function Landing() {
    return(
        <div className="">
            <Home style={{height: "100vph" ,}}/>
             <Info/>
             <Services/>
             <About/>
             <Branches/>
             <Sessions/>
             <DronePilotingSessions/>
             <VirtualRealitySessions/>
             
             <SessionsCoding/>
             <Hub/>
             <SatBanner/>
             <Gallery/>
             <Testimonials/>
             <Partners/>
             
             

            {/* <Pricing/> */} 
        </div>
    );
}
export default Landing;