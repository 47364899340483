import React, { useState } from 'react';
import { Row,Container,Col } from 'react-bootstrap';
import { FaUserFriends } from "react-icons/fa";
import {  RiFlashlightLine,RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import { WiLightning } from "react-icons/wi";
import { GrSecure } from "react-icons/gr";
import ReactPlayer from 'react-player'

//visible header that contains LOGO and Navigation Icon
import "./Info.css";
import "../../assets/css/custom.css";
 import neximage from "../../assets/img/nex.jpg";
 import promo from "../../assets/video/promo.mp4";
// import easytouse from "../../assets/img/undraw_Usability_testing_re_uu1g.svg";
// import secure from "../../assets/img/undraw_Security_on_re_e491.svg";
 import feature1 from "../../assets/img/MG9481.jpg";
// import feature2 from "../../assets/img/feature-2.png";
// import feature3 from "../../assets/img/feature-2.png";


function About() {
    return(
        <section className="section clearfix no-views secPadding" data-animation-effect="fadeIn">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h1 id="about" className="title text-center">About <span>NEXGEN </span></h1>
						<div className="space"></div>
						<div className="row">
							<div className="col-md-6">
								<img src={neximage} alt="Our portrait"/>
								<div className="space"></div>
							</div>
							<div className="col-md-6">
								<p>
								Nexgen Educational Hub was founded on its core principle of relevance. It has since its inception been dedicated to equipping students with skills that enable them stay relevant in such a fast-changing world.
								</p>
								<p>
								Nexgen has lived up to its vision of serving the next generation through its carefully selected programmes and activities for kids notable among which are our robotics programme, drone piloting programme, virtual reality programmes, as well as our coding and web design programmes. Nexgen has also partnered with leading basic schools in the country to run after-school programmes in coding and robotics as well as workshops, expos and competitions.								</p>
								<p>
								Our team of skilled, dedicated and highly motivated instructors are guaranteed to give the best tuition our students deserve, in the most conducive learning environment and at the most affordable tuition fees.	
								</p>
								{/* <p>Currently, our robotics training centres are located in East Legon (<a className="fw-bold text-danger" target='_blank' href='https://www.oneheart.academy/'>One Heart Africa Academy</a>) and Abelemkpe (<span className="fw-bold text-primary">Immanuel Restoration School Complex</span>).</p> */}

								<p>
								Nexgen also offers prep classes for prospective SAT test takers. We have a special commitment to helping students land their dream Ivy League Universities. Our strategies have been tried and tested and have proven to help students strategically ace the test in under 8 weeks of tuition. We also offer guidance, counselling and advisory services in the entire college application process.
								</p>
								<p>
								Nexgen Educational Hub is definitely your ideal solution if you desire to secure a place for your ward in the future of a fast-changing world and advancing technological climate. 	
								</p>
							</div>
						</div>
						<div className="space"></div>
						<h2>THE NEXBOT PROGRAMME</h2>
						<div className="row">
							<div className="col-md-6">
								<p>
								Nexbot is our flagship robotics programme designed to train children in the essentials of robotics automation, programming and artificial intelligence. 
								Our junior robotics program is best suited for kids aged 5+ years while our senior robotics program is designed for kids aged 9+ years. Both programs run on Saturday and span a period of 8 weeks. The Nexbot programme focuses on ensuring our students are imbued with these key skills for the future (according to the World Economic Forum):									
								</p>
									<ul className="list-unstyled">
									<li><i className="fa fa-arrow-circle-right pr-10 colored"></i> Analytical thinking and Innovation</li>
									<li><i className="fa fa-arrow-circle-right pr-10 colored"></i> Active Learning and Learning strategies</li>
									<li><i className="fa fa-arrow-circle-right pr-10 colored"></i> Complex problem-solving</li>
									<li><i className="fa fa-arrow-circle-right pr-10 colored"></i> Critical thinking and analysis</li>
									<li><i className="fa fa-arrow-circle-right pr-10 colored"></i> Creativity, Originality and Initiative</li>
									<li><i className="fa fa-arrow-circle-right pr-10 colored"></i> Technology use, monitoring and control</li>
									<li><i className="fa fa-arrow-circle-right pr-10 colored"></i> Technology design and programming</li>
									<li><i className="fa fa-arrow-circle-right pr-10 colored"></i> Reasoning, problem-solving and ideation</li>
								</ul>
							</div>
							<div className="col-md-6">
								{/* <img src={feature1} alt="Our portrait"/>
								 */}
								<div className='player-wrapper'>

									<ReactPlayer url={promo} controls className='react-player'
									width='100%'
									height='100%'
									/>
								</div>
								<div className="space"></div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</section>


    );
}
export default About;











