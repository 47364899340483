import React, { useEffect, useState } from 'react';

//visible header that contains LOGO and Navigation Icon
import "../../views/Landing/Hub.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import slide1 from "../../assets/img/Partners/gh_library.jpg";
import slide2 from "../../assets/img/Partners/OHAAlogo.jpg";
import slide3 from "../../assets/img/Partners/springforth.jpg";
import slide4 from "../../assets/img/Partners/littleexplorersLogo.png";
import slide5 from "../../assets/img/Partners/tvafrica.jpeg";
import slide6 from "../../assets/img/Partners/JCSSLogo.jpg";
import slide7 from "../../assets/img/Partners/JLMLogoSmall.png";
import slide8 from "../../assets/img/Partners/KiselLogo.png";
import slide9 from "../../assets/img/Partners/BloomingsMonPlus.png";



 function PartnerSlider() {
 
 
      const settings = {
        className: "center",
        centerMode: true,
        centerPadding: "60px",
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,

        };



  
  return(
        
        
          <div className='slidewraper my-auto  ' >
            <Slider {...settings}>
            <div className='align-self-center'><img src={slide9}  width={150} height={150}  alt="img" className='img-fluid' /></div>
            <div className='align-self-center'><img src={slide2}  width={150} height={150}  alt="img" className='img-fluid' /></div>
            <div className='align-self-center'><img src={slide4}  width={150} height={150}  alt="img" className='img-fluid' /></div>
            <div className='align-self-center'><img src={slide8}  width={150} height={150}  alt="img" className='img-fluid' /></div>
            <div className='align-self-center'><img src={slide7}  width={150} height={150}  alt="img" className='img-fluid' /></div>
            <div className='align-self-center'><img src={slide6}  width={150} height={150}  alt="img" className='img-fluid' /></div>
            <div className='align-self-center'><img src={slide1}  width={150} height={150}  alt="img" className='img-fluid' /></div>
            <div className='align-self-center'><img src={slide3}  width={150} height={150}  alt="img" className='img-fluid' /></div>
            <div className='align-self-center'><img src={slide5}  width={150} height={150}  alt="img" className='img-fluid' /></div>

           

          </Slider>
          </div>
      
    );
}
export default PartnerSlider;












