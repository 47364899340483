import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table,Alert,Form,InputGroup,ProgressBar,Divider} from 'react-bootstrap';

// import { useAuth } from '../../Context/AuthContext';
// material ui imports

 import style from 'bootstrap/dist/css/bootstrap.min.css';
 import {PaystackButton} from "react-paystack";
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from '@firebase/storage';
import AddUser from '../Hooks/useAddUser';
import { FaLastfmSquare } from 'react-icons/fa';
import AddUserAdmin from '../Hooks/useAddUserAdmin';
import AddVirtualUserAdmin from '../Hooks/useAddVirtualUserAdmin';
// import { DropzoneArea } from 'material-ui-dropzone';

//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();

const AddUserFormAdmin =({type,location,coursetype="inperson"})=>{
     // files to upload
  const [data, setData] = useState({session:type,location:location, pemail:"" });
  const [mainError, setMainError] = useState('');
  const [img, setImg] = useState();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(''); // sets Error from operations
  const [formError, setFormError] = useState([]); // check error from forms
  const [success, setSuccess] = useState(false);
  const [paid, setPaid] = useState(false);
  const [checked,setChecked] = useState(false)
  // const { userID } = useAuth();
  var price = 650;
   

  const UpdateData = (item, value) => {
    setData((data) => ({ ...data, [item]: value }));
    setFormError((formError) => ({ ...formError, [item]: "" }));
   
  };

  const checkEventHandler = (event) => {
    let nam = event.target.name;

     setChecked(!checked)
     let val = !checked
    UpdateData(nam, val);
  };
  const eventHandler = (event) => {
    let val = event.target.value;
    let nam = event.target.name;
    UpdateData(nam, val);
  };

  
  function getAge(birthYear){
    var currentDate = new Date();
    var dob = new Date(birthYear).getFullYear();
      var currentYear = currentDate.getFullYear();
     var age = currentYear - dob;
      console.log(age);
      return age;

  }
  getAge(data.dob);

  const findFormErrors = () => {
    const newErrors = {};
    // name errors
    if (!data.firstname || data.firstname === "") newErrors.firstname = "Cannot be blank!";
    else if (data.firstname.length > 100) newErrors.firstname = "Name is too long!";
    // name errors
    if (!data.lastname || data.lastname === "") newErrors.lastname = "Cannot be blank!";
    else if (data.lastname.length > 100) newErrors.lastname = "Name is too long!";
    // name errors
    if (!data.pname || data.pname === "") newErrors.pname = "Cannot be blank!";
    else if (data.pname.length > 100) newErrors.pname = "Name is too long!";
    // name errors
    if (!data.dob || data.dob === "") newErrors.dob = "Cannot be blank!";
    else if (getAge(data.dob) < type.minage) newErrors.dob = "Year not eligible!";
    // name errors
    if (!data.pemail || data.pemail === "") newErrors.pemail = "Add a valid email!";
    else if (data.pemail.length > 100) newErrors.pemail = "Name is too long!";
    // food errors
    if ( !data.pcontact || data.pcontact === '' ) newErrors.pcontact = 'Add a valid Contact!';
    if ( !data.paddress || data.paddress === '' ) newErrors.paddress = 'Add a valid address!';
    if ( !data.school || data.school === '' ) newErrors.school = 'Add your wards school!';

    return newErrors;
  };

  const Submit = async (reference) => {
    // Add Product
    setPaid(true)
    if (coursetype !=="virtual"){
      const { error, success } = await AddUserAdmin(data, type,reference);
      setMainError(error);
      setSuccess(success);
    }else{
      const { error, success } = await AddVirtualUserAdmin(data, type,reference);
      setMainError(error);
      setSuccess(success);
    }
    // setData({ name: "", email: "", session:type });
    setLoading(false);
    // closeModal()
  };

  const Proceed = async () => {
    // event.preventDefault();
    setLoading(true);

    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setFormError(newErrors);
      setLoading(false);
      setValidated(false)

    } else {
      // No errors! Put any logic here for the form submission!
      setLoading(true);
      setValidated(true)
    }
  };




  
  




    // PAYSTACK INTEGRATION

  // SUCCESSFULLY PAID
  // const handlePaystackSuccessAction = async(reference) => {
  //   setLoading(true)
  //   setError(error);
  //   setSuccess(success);
  //   setPaid(true);
  //   setLoading(false)
  //   Submit(reference)
  //   console.log(reference);

  // };

  // const handlePaystackCloseAction = () => {
  //   // implementation for  whatever you want to do when the Paystack dialog closed.
  //   console.log("closed");
    


  // };


  // const config = {
  //   reference: (new Date()).getTime().toString(),
  //   currency: "GHS",
  //   email: data.pemail,
  //   amount: 100*price,
  //   publicKey: "pk_live_2ecef1c81a94e50860200c001677efee909e0a96",

  // };

  // const componentProps = {
  //   ...config,
  //   text: "Pay GH₵"+price,
  //   onSuccess: (reference) => handlePaystackSuccessAction(reference),
  //   onClose: () => handlePaystackCloseAction(),

  // };

  const output = (
    <div className={style}>
    <Form noValidate validated={validated} onSubmit={Proceed} inline>

      <h3>Student Information</h3>
        <hr/>
        {/*user details */}
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            First Name
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="firstname"
              required={true}
              value={data.firstname}
              onChange={eventHandler}
              isInvalid={!!formError.firstname}
            />
            <Form.Control.Feedback type="invalid">
              {formError.firstname}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Last Name
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="lastname"
              required={true}
              value={data.lastname}
              onChange={eventHandler}
              isInvalid={!!formError.lastname}
            />
            <Form.Control.Feedback type="invalid">
              {formError.lastname}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3 align-bottom text-md-end mx-auto my-auto">
            Other Names
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="othername"
              required={true}
              value={data.othername}
              onChange={eventHandler}
              isInvalid={!!formError.othername}
            />
            <Form.Control.Feedback type="invalid">
              {formError.othername}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3 align-bottom text-md-end mx-auto my-auto">
            Date of Birth
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="date"
              name="dob"
              required={true}
              value={data.dob}
              onChange={eventHandler}
              isInvalid={!!formError.dob}
            />
            <Form.Control.Feedback type="invalid">
              {formError.dob}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            E-mail
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="email"
              name="email"
              required={true}
              value={data.email}
              onChange={eventHandler}
              isInvalid={!!formError.email}
            />
            <Form.Control.Feedback type="invalid">
              {formError.email}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Contact
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="contact"
              required={true}
              value={data.contact}
              onChange={eventHandler}
              isInvalid={!!formError.contact}
            />
            <Form.Control.Feedback type="invalid">
              {formError.contact}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            School
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="school"
              required={true}
              value={data.school}
              onChange={eventHandler}
              isInvalid={!!formError.school}
            />
            <Form.Control.Feedback type="invalid">
              {formError.school}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        {/* <Form.Group className="row m-2" controlId="validationCustom07">
                    <Form.Label className="col-3 align-bottom my-auto text-end">
                    Photo
                    </Form.Label>
                    <Col>
                    {progress !== 0 && <ProgressBar striped now={progress} />}
                    <DropzoneArea

                        acceptedFiles={["image/*"]}
                        dropzoneText={"Upload candidate photo"}
                        onChange={(files) => handleChange(files)}
                        maxFileSize={3000000}
                        filesLimit={1}
                    />
                {formError.img && <div className="alert-danger">{formError.img} </div>}

                    </Col>
                </Form.Group> */}
     
        <hr/>
        <h3>Guardian Information</h3>
        <hr/>
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
          Parents Full Name
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="pname"
              required={true}
              value={data.pname}
              onChange={eventHandler}
              isInvalid={!!formError.pname}
            />
            <Form.Control.Feedback type="invalid">
              {formError.pname}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        
  
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Parents Email
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="email"
              name="pemail"
              required={true}
              value={data.pemail}
              onChange={eventHandler}
              isInvalid={!!formError.pemail}
            />
            <Form.Control.Feedback type="invalid">
              {formError.pemail}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Parents Contact
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="pcontact"
              required={true}
              value={data.pcontact}
              onChange={eventHandler}
              isInvalid={!!formError.pcontact}
            />
            <Form.Control.Feedback type="invalid">
              {formError.pcontact}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Occupation
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="poccupation"
              required={true}
              value={data.poccupation}
              onChange={eventHandler}
              isInvalid={!!formError.poccupation}
            />
            <Form.Control.Feedback type="invalid">
              {formError.poccupation}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Address
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="paddress"
              required={true}
              value={data.paddress}
              onChange={eventHandler}
              isInvalid={!!formError.paddress}
            />
            <Form.Control.Feedback type="invalid">
              {formError.paddress}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
    <Form.Group className="row" controlId="validationCustom01 formBasicCheckbox">
    <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
           
          </Form.Label>
          <InputGroup className="form-input col">

            <Form.Check 
            type="checkbox" 
            label="I will like my ward to be featured in promotional videos and photos of the Nexbot program" 
            name='mediaconsent'
            checked = {checked}
            onChange={checkEventHandler}
            />
            </InputGroup>
          </Form.Group>


        <Col className="text-center">
                    <Button
                    onClick={()=>Proceed()}
                    className=" btn-primary text-center"
                    >
                    Proceed {(loading && !error)   &&  <Spinner animation="border" variant="white"/> }
                    </Button>

                </Col>
                </Form>

    </div>
    )

    return(
        <>
        {(error ) && <Alert variant="danger"><h3 className="text-muted mx-auto">{error}</h3></Alert>}
            
            {success && <Alert variant="success">
            <h3 className="mx-auto">{"Registration Succesfuly"}</h3>
              <span className="mx-auto">Kindly check your mail "{data.pemail}" for confirmation and reciepts, Thank you! </span>
            </Alert> }
            {(!error && !success && !validated)   &&  output }
            {
            (paid && !success )   &&  <div className="mx-auto text-center" >
              <Spinner className="mx-auto" animation="border" variant="info"/> 
              
              </div>

        }
            {(!error && !success && !paid && validated)   &&  <Col className="text-center">
              <h3>
                You are almost there...
              </h3>
              <h6 className="text-warning">Are you sure?</h6>
              <Button className="m-1" onClick={()=>Submit("from admin")}  >Add student</Button>
        </Col> }

            
        </>
     
    );
}

export default AddUserFormAdmin;